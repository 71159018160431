<template>
  <container ref="container" class="joinUs" @error="init">
    <!-- 顶部 -->
    <publicHeader></publicHeader>

    <!-- 横幅 -->
    <publicBanner :src="banner"></publicBanner>

    <!-- 面包屑导航 -->
    <breadcrumb :router="breadcrumb.router"></breadcrumb>

    <!-- 路由 -->
    <router-view></router-view>

    <!-- 底部 -->
    <publicFooter></publicFooter>

    <!-- 联系 -->
    <contact></contact>
  </container>
</template>

<script>
import publicHeader from "@/components/publicHeader.vue";
import publicFooter from "@/components/publicFooter.vue";
import publicBanner from "@/components/publicBanner.vue";
import contact from "@/components/contact.vue";
import breadcrumb from "@/components/breadcrumb.vue";

export default {
  name: "joinUs",
  components: {
    publicHeader,
    publicFooter,
    publicBanner,
    breadcrumb,
    contact,
  },
  data() {
    return {
      breadcrumb: {
        router: ["首页", "招商加盟"],
      },

      banner: "",
    };
  },
  mounted() {
    // 初始化数据
    this.init();
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      try {
        // 设置状态
        this.$refs.container.status("init", {
          height: "100vh",
        });

        // 获取数据
        const [banner] = await Promise.all([
          this.$axios("/api/mainstation/headImage", {
            params: {
              type: 25,
            },
          }),
        ]);

        // 设置数据
        this.banner = banner.data;

        // 设置状态
        this.$refs.container.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.container.status("error", {
          text: e.message,
          height: "100vh",
        });
      }
    },

    /**
     * 导航更改
     */
    async navChange(index) {
      try {
        // 变更导航
        this.nav.index = index;

        // 设置状态
        this.$refs.joinUsContent.status("init", {
          height: "100vh",
        });

        // 获取数据
        const result = await this.$axios("/api/mainstation/dynamicContent", {
          params: {
            type: this.nav.list[index].id,
          },
        });

        // 设置数据
        this.detail = result.data;

        // 设置状态
        this.$refs.joinUsContent.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.joinUsContent.status("error", {
          text: e.message,
          height: "100vh",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  // 加入我们
  .joinUs {
    // 加入我们-新闻
    &-news {
      width: 1200px;
      margin: auto;
      margin-top: 45px;
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid #eaeaea;

      // 加入我们-新闻-顶部
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
          font-size: 18px;
          color: #fff;
          background-color: #ed207b;
          width: 160px;
          height: 50px;
          line-height: 50px;
          text-align: center;
        }

        &-more {
          font-size: 18px;
          color: #707070;
          padding: 0 25px;
        }
      }

      // 详情
      &-detail {
        display: flex;
        padding-top: 15px;
        padding-bottom: 50px;
      }

      &-cover {
        width: 370px;
        height: 288px;
        margin: 0 30px;

        img {
          @include cover;
        }
      }

      // 列表
      &-list {
        flex: 1;
        width: 0;
        padding-right: 25px;
      }

      // 元素
      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 32px;

        // 元素-标题
        &-title {
          color: #292929;
          font-size: 16px;
          width: 0;
          flex: 1;

          @include ellipsis;
        }

        // 元素-日期
        &-date {
          color: #bababa;
          font-size: 16px;
        }
      }
    }

    // 导航
    &-nav {
      width: 1200px;
      margin: auto;
      height: 80px;
      border-radius: 10px;
      border: 1px solid #bfbfbf;
      display: flex;
      margin-top: 30px;
      overflow: hidden;

      // 导航-元素
      &-item {
        color: #282828;
        flex: 1;
        width: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;

        &:not(:last-of-type) {
          border-right: 1px solid #bfbfbf;
        }

        &_active {
          background-color: #ed207b;
          color: #fff;
        }
      }
    }

    // 内容
    &-content {
      width: 1200px;
      margin: 60px auto;
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  // 加入我们
  .joinUs {
    // 加入我们-新闻
    &-news {
      margin: auto rpx(30);
      margin-top: rpx(40);
      border-radius: rpx(20);
      overflow: hidden;
      border: rpx(2) solid #eaeaea;

      // 加入我们-新闻-顶部
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
          font-size: rpx(24);
          color: #fff;
          background-color: #ed207b;
          width: rpx(150);
          height: rpx(50);
          line-height: rpx(50);
          text-align: center;
        }

        &-more {
          font-size: 18px;
          color: #707070;
          padding: 0 25px;
        }
      }

      // 详情
      &-detail {
        display: flex;
        padding-top: rpx(20);
        padding-bottom: rpx(20);
      }

      &-cover {
        display: none;
      }

      // 列表
      &-list {
        flex: 1;
        width: 0;
        padding: 0 rpx(15);
      }

      // 元素
      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: rpx(40);

        // 元素-标题
        &-title {
          color: #292929;
          font-size: rpx(24);
          width: 0;
          flex: 1;

          @include ellipsis;
        }

        // 元素-日期
        &-date {
          color: #bababa;
          font-size: rpx(24);
        }
      }
    }

    // 导航
    &-nav {
      margin: auto rpx(30);
      height: rpx(80);
      border-radius: rpx(15);
      border: rpx(2) solid #bfbfbf;
      display: flex;
      margin-top: 30px;
      overflow-x: scroll;

      // 导航-元素
      &-item {
        color: #282828;
        white-space: nowrap;
        padding: 0 rpx(30);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: rpx(24);
        scroll-behavior: smooth;

        &:not(:last-of-type) {
          border-right: rpx(2) solid #bfbfbf;
        }

        &_active {
          background-color: #ed207b;
          color: #fff;
        }
      }
    }

    // 内容
    &-content {
      margin: rpx(60) rpx(30);
    }
  }
}
</style>
